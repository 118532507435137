<template>
  <div>
    <section id="account-section" class="section section-secondary jpadding">
      <div class="jcard jcard-main jcard-nohover">
        <!------------ title  ------------>
        <div class="card-title">
          <h3>DUKE Watch</h3>
          <!-- <button @click="showChangeEmailDialog" class="jbtn-text ml-3">
            <span class="fa fa-pencil"></span>
          </button> -->
        </div>

        <!------------ overview  ------------>
        <div id="talkii-id" class="">
          <div class="mb-3">
            <i class="fal fa-watch fa-4x" style="color: #ca9e81"></i>
          </div>
          <p>
            <small>ID</small> <strong>{{ watchId.substring(0, 12) }}</strong>
          </p>
          <p>
            <small>OWNER</small> <strong>{{ user.email }}</strong>
            <router-link :to="{ name: 'UserAccount', params: { id: userId } }" class="ml-2">
              <i class="fal fa-search"></i>
            </router-link>
          </p>

          <div class="d-flex">
            <p>
              <small>MODEL</small><br /><label class="badge badge-pill badge-secondary">{{
                model.name.toUpperCase()
              }}</label>
            </p>
            <p class="ml-3">
              <small>STATUS</small><br />
              <label class="badge badge-pill badge-success">{{ $C.STATUS.WATCH_STRINGS[watch.status].toUpperCase()
              }}</label>
              <!-- <label class="badge badge-pill" v-bind:class="{
                'badge-success': hasActiveStatus(watch.status),
                'badge-danger': !hasActiveStatus(watch.status)
              }">{{ $C.STATUS.WATCH_STRINGS[watch.status].toUpperCase() }}</label> -->
            </p>
          </div>
          <!-- <button class="jbtn jbtn-outline jbtn-xs my-2">
            Do Stuff
          </button> -->
          <!-- <p><small>EMAIL</small><br><strong>{{ user.email }}</strong></p>
          <p><small>TALKII ID</small><br><strong>{{ userId.substring(0, 12) }}</strong></p> -->
        </div>

        <!-- separator -->
        <div class="row">
          <div class="col">
            <hr />
          </div>
        </div>

        <!------------ information  ------------>
        <div class="card-title mt-3">
          <h3>Watch information</h3>
          <button @click="showEditWatchDialog" class="jbtn-text ml-3">
            <span class="fa fa-pencil"></span>
          </button>
        </div>

        <div class="watch-content watch-right">
          <div class="jcard-dark jcard-watch-info">
            <div class="jcard-watch-info-grid">
              <!-- column 2 -->
              <div>
                <div class="jcard-info-item">
                  <!-- <p class="p1-platform"><span class="p-grey">Model</span> {{ model.name }}</p> -->
                  <p class="p1-platform p-grey">Model</p>
                  <p class="p1-platform">{{ model.name }}</p>
                </div>
                <div class="jcard-info-item">
                  <p class="p1-platform p-grey">Serial</p>
                  <p class="p1-platform">{{ watch.serial }}</p>
                </div>
                <div class="jcard-info-item">
                  <p class="p1-platform p-grey">Limited Edition</p>
                  <p class="p1-platform">{{ model.edition }}</p>
                </div>
              </div>
              <!-- column 3 -->
              <div>
                <div class="jcard-info-item">
                  <p class="p1-platform p-grey">Purchase Date</p>
                  <p class="p1-platform">
                    {{ $moment($C.getDateFromTimestamp(watch.purchasedOn)).format("DD/MM/YYYY") }}
                  </p>
                </div>
                <div class="jcard-info-item">
                  <p class="p1-platform p-grey">Purchase Country</p>
                  <p class="p1-platform">{{ watch.purchasedAt }}</p>
                </div>
                <div class="jcard-info-item">
                  <p class="p1-platform p-grey">Owner's Name</p>
                  <p class="p1-platform">{{ user.firstName }} {{ user.lastName }}</p>
                </div>
                <div class="jcard-info-item">
                  <p class="p1-platform p-grey">Warranty Expiry</p>
                  <p class="p1-platform">
                    {{ $moment($C.getDateFromTimestamp(watch.expiry)).format("MM/YYYY") }}
                  </p>
                </div>
                <!-- <div class="jcard-info-item">
                  <p class="p1-platform p-grey">Technical Info</p>
                  <p class="p1-platform"><i class="fal fa-external-link"></i></p>
                </div> -->
              </div>
            </div>
          </div>
        </div>


        <!-- <div>
          <div class="row">
            <div class="col">
              <label><strong><i class="fal fa-watch"></i> Model</strong></label>
              <p>
                <label class="badge badge-pill badge-secondary">{{ model.name.toUpperCase() }}</label>
              </p>
            </div>
            <div class="col">
              <label><strong><i class="fal fa-barcode-alt"></i> Serial Number</strong></label>
              <p>{{ watch.serial }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label><strong><i class="fal fa-id-card"></i> Owner</strong></label>
              <p>
                {{ user.firstName + " " + user.lastName }}&nbsp;
                <router-link :to="{ name: 'UserAccount', params: {id: userId}}" class="ml-2"><i
                    class="fal fa-external-link"></i></router-link>
              </p>
            </div>
            <div class="col">
              <label><strong><i class="fal fa-exclamation-circle"></i> Status</strong></label>
              <p>
                <label class="badge badge-pill" v-bind:class="{
                  'badge-success': hasActiveStatus(watch.status),
                  'badge-danger': !hasActiveStatus(watch.status)
                }">{{ $C.STATUS.WATCH_STRINGS[watch.status].toUpperCase() }}</label>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label><strong><i class="fal fa-calendar-alt"></i> Purchase Date</strong></label>
              <p>
                {{ $moment($C.getDateFromTimestamp(watch.purchasedOn)).format("DD-MM-YYYY") }}
              </p>
            </div>
            <div class="col">
              <label><strong><i class="fal fa-globe-europe"></i> Purchase Location</strong></label>
              <p>{{ watch.purchasedAt }}</p>
            </div>
          </div>
        </div> -->

        <div class="row">
          <div class="col">
            <hr />
          </div>
        </div>

        <!------------ warranty ------------>
        <div class="card-title mt-3">
          <h3>Warranty</h3>
        </div>

        <div v-if="showErrorWarranty" key="error" class="jblock">
          <p>This watch has no active warranty at the moment.</p>
        </div>

        <div v-else key="warranty">
          <!-- current warranty (not slot) -->
          <div class="jblock jblock-grid">
            <!-- warranty item -->
            <div class="jcard-medium">
              <div class="d-flex">
                <h5><i class="fal fa-file-certificate"></i> Warranty #{{ watch.warrantyActive + 1 }}</h5>
                <div class="ml-auto">
                  <b-dropdown right variant="link" toggle-class="jbtn jbtn-icon jbtn-icon-link" class="ml-auto" no-caret>
                    <template #button-content>
                      <i class="fa fa-ellipsis-v" key="warranty"></i>
                    </template>
                    <b-dropdown-item @click="showEditWarrantyDialog(watch.warrantyActive)"><i
                        class="fal fa-pencil mr-2"></i> Edit warranty (Slot
                      {{ watch.warrantyActive + 1 }})</b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <hr />
              <p>
                <strong>Status: </strong>
                <label class="badge badge-pill" v-bind:class="{
                  'badge-success': $C.STATUS.isActiveWarrantyStatus(getActiveWarranty(watch).status),
                  'badge-danger': !$C.STATUS.isActiveWarrantyStatus(getActiveWarranty(watch).status)
                }">{{ $C.STATUS.WARRANTY_STRINGS[getActiveWarranty(watch).status].toUpperCase() }}</label>
              </p>
              <p>
                <strong>Starting Date:</strong>
                {{ $moment($C.getDateFromTimestamp(getActiveWarranty(watch).start)).format("DD-MM-YYYY") }}
              </p>
              <p>
                <strong>Expiration Date:</strong>
                {{ $moment($C.getDateFromTimestamp(getActiveWarranty(watch).end)).format("DD-MM-YYYY") }}
              </p>
              <hr />
              <!-- <p class="text-center mt-4"><i class="fal fa-file-pdf fa-2x"></i><br />Conditions Pdf</p> -->
            </div>
          </div>

          <!-- warranty btn container -->
          <div class="btn-container text-center mt-4 mb-4">
            <button class="jbtn jbtn-outline jbtn-xs my-2" @click="showWarrantySlots = !showWarrantySlots">
              <i class="fal fa-history"></i> Warranty Slots
            </button>
          </div>

          <!-- warranty slots -->
          <div v-if="showWarrantySlots" class="jblock jblock-grid-3">
            <!-- warranty item -->
            <div v-for="(warranty, index) in watch.warranties" :key="index" class="jcard-mini">
              <div class="d-flex align-items-center">
                <h5>Warranty Slot {{ index + 1 }}</h5>
                <div class="ml-auto">
                  <b-dropdown right variant="link" toggle-class="jbtn jbtn-icon jbtn-icon-link" class="ml-auto" no-caret>
                    <template #button-content>
                      <i class="fa fa-ellipsis-v" :key="'warranty' + index"></i>
                    </template>
                    <b-dropdown-item @click="setActiveWarranty(index)"><i class="fal fa-check mr-2"></i> Set active
                      warranty</b-dropdown-item>
                    <b-dropdown-item @click="showEditWarrantyDialog(index)"><i class="fal fa-pencil mr-2"></i> Edit
                      warranty</b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <hr />
              <p>
                <strong>Status: </strong>
                <label class="badge badge-pill" v-bind:class="{
                  'badge-success': $C.STATUS.isActiveWarrantyStatus(warranty.status),
                  'badge-danger': !$C.STATUS.isActiveWarrantyStatus(warranty.status)
                }">{{ $C.STATUS.WARRANTY_STRINGS[warranty.status].toUpperCase() }}</label>
              </p>
              <p>
                <strong>Start:</strong>
                {{ $moment($C.getDateFromTimestamp(warranty.start)).format("DD-MM-YYYY") }}
              </p>
              <p>
                <strong>Expiry:</strong>
                {{ $moment($C.getDateFromTimestamp(warranty.end)).format("DD-MM-YYYY") }}
              </p>
              <hr />
              <!-- <p class="text-center mt-4"><i class="fal fa-file-pdf"></i><br />Conditions Pdf</p> -->
            </div>
          </div>
        </div>

        <!------------ delivery ----------->
        <div v-if="false">
          <div class="card-title mt-3">
            <h3>Deliveries</h3>
            <button v-if="showErrorDelivery" key="add-delivery-btn" class="jbtn-text ml-3" @click="showAddDeliveryDialog">
              <span class="fa fa-plus"></span>
            </button>
            <button v-else key="edit-delivery-btn" class="jbtn-text text-red ml-3" @click="deleteDelivery">
              <span class="fa fa-trash"></span>
            </button>
          </div>

          <div v-if="showErrorDelivery" key="error-delivery" class="jblock">
            <p>This watch has no deliveries at the moment.</p>
          </div>

          <div v-if="!showErrorDelivery" key="delivery">
            <!-- current/latest delivery -->
            <div class="jblock jblock-grid">
              <!-- delivery item -->
              <div class="jcard-medium">
                <div class="d-flex">
                  <h5><i class="fal fa-truck"></i> Delivery</h5>
                  <div class="ml-auto">
                    <b-dropdown right variant="link" toggle-class="jbtn jbtn-icon jbtn-icon-link" class="ml-auto"
                      no-caret>
                      <template #button-content>
                        <i class="fa fa-ellipsis-v" key="delivery"></i>
                      </template>
                      <b-dropdown-item @click="showEditDeliveryDialog()"><i class="fal fa-pencil mr-2"></i> Edit
                        delivery</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <hr />
                <p>
                  <strong>Status: </strong>
                  <label class="badge badge-pill" v-bind:class="{
                    'badge-success': $C.STATUS.isActiveWarrantyStatus(watch.delivery.status),
                    'badge-danger': !$C.STATUS.isActiveWarrantyStatus(watch.delivery.status)
                  }">{{ $C.STATUS.WARRANTY_STRINGS[watch.delivery.status].toUpperCase() }}</label>
                </p>
                <p>
                  <strong>Dispatch Date:</strong>
                  {{ $moment($C.getDateFromTimestamp(watch.delivery.dispatch)).format("DD-MM-YYYY") }}
                </p>
                <p>
                  <strong>Expected Arrival Date:</strong>
                  {{ $moment($C.getDateFromTimestamp(watch.delivery.arrival)).format("DD-MM-YYYY") }}
                </p>
                <p>
                  <strong>Carrier:</strong>
                  {{ watch.delivery.carrier != "" ? watch.delivery.carrier : " No Carrier information." }}
                </p>
                <p>
                  <strong>Taracking ID:</strong>
                  {{ watch.delivery.trackingId != "" ? watch.delivery.trackingId : " No ID." }}
                </p>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- ----- edit watch modal --------------------------------- -->
    <b-modal :hide-header="true" :hide-footer="true" id="edit-watch-modal" ref="edit-watch-modal" centered
      title="BootstrapVue">
      <div class="jdialog-top">
        <div class="close-container">
          <h3><strong>Edit watch</strong></h3>
          <span @click="$bvModal.hide('edit-watch-modal')" class="ml-auto jclose"><i class="fa fa-times "></i></span>
        </div>
        <p><small>Keep this information up to date.</small></p>
      </div>
      <div class="jdialog-main">
        <div class="form-row">
          <!-- serial number -->
          <div class="col-md">
            <div class="form-group">
              <label for="serial"><i class="fal fa-barcode-alt"></i> Serial Number</label>
              <input type="text" class="form-control" v-model="editData.serial" />
            </div>
          </div>

          <!-- status -->
          <div class="col-md">
            <div class="form-group">
              <label for="status"><i class="fal fa-exclamation-circle"></i> Status</label>
              <b-form-select v-model="editData.status" :options="watchStatusOptions"></b-form-select>
              <div class="invalid-feedback">
                Please select a valid status.
              </div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <!-- purchase date -->
          <div class="col-md">
            <div class="form-group">
              <label for="purchasedOn"><i class="fal fa-calendar-alt"></i> Purchase Date</label>

              <input type="date" v-bind:class="{
                'form-control': true,
                'is-invalid': !validDate(editData.purchasedOn) && bluredPurchasedOn
              }" v-on:blur="bluredPurchasedOn = true" v-model="editData.purchasedOn" />
              <!-- <Datepicker
                    id="datepicker"
                    v-model="editData.purchasedOn"
                    lang="en"
                  /> -->

              <div class="invalid-feedback">
                Please enter when the watch was purhcased.
              </div>
            </div>
          </div>

          <!-- purchase location -->
          <div class="col-md">
            <div class="form-group">
              <label for="purchasedAt"><i class="fal fa-globe-europe"></i> Purchase Location</label>
              <input type="text" v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.purchasedAt) && bluredPurchasedAt
              }" v-on:blur="bluredPurchasedAt = true" v-model="editData.purchasedAt" />
              <div class="invalid-feedback">
                Please enter where the watch was purchased.
              </div>
            </div>
          </div>
        </div>

        <!-- alert -->
        <b-alert v-if="showInputError" show variant="danger"><i class="fad fa-exclamation-circle"></i>
          {{ validationMsg }}
        </b-alert>
      </div>

      <!-- dialog bottom -->
      <div class="jdialog-bottom with-cancel">
        <button @click="$bvModal.hide('edit-watch-modal')" class="jbtn jbtn-sm jbtn-red">
          <i class="fa fa-times"></i> Cancel
        </button>
        <button @click="updateWatchInfo" class="jbtn jbtn-sm"><i class="fa fa-save"></i> Update</button>
      </div>
    </b-modal>

    <!-- ----- edit warranty modal --------------------------------- -->
    <b-modal :hide-header="true" :hide-footer="true" id="edit-warranty-modal" ref="edit-warranty-modal" centered
      title="BootstrapVue">
      <div class="jdialog-top">
        <div class="close-container">
          <h3>
            <strong>Edit Warranty {{ warrantyEditData.index + 1 }}</strong>
          </h3>
          <span @click="$bvModal.hide('edit-warranty-modal')" class="ml-auto jclose"><i class="fa fa-times "></i></span>
        </div>
        <p>
          <small>Any DUKE Watch can only have one active warranty at the time. In total 3 warranty slots are
            available.</small>
        </p>
      </div>
      <div class="jdialog-main">
        <!-- row 
        <div class="form-row">-->
        <!-- name 
          <div class="col-md">
            <div class="form-group">
              <label for="serial"><i class="fal fa-tag"></i> Name</label>
              <input
                type="text"
                v-bind:class="{
                  'form-control': true
                }"
                v-model="warrantyEditData.name"
              />
              <small>Optional.</small>
            </div>
          </div>-->

        <!-- status 
          <div class="col-md">
            <div class="form-group">
              <label for="status"><i class="fal fa-exclamation-circle"></i> Status</label>
              <b-form-select
                v-model="warrantyEditData.status"
                :options="$C.getFormattedOptionsArray($C.STATUS.WARRANTY_STRINGS)"
              ></b-form-select>
              <div class="invalid-feedback">
                Please select a valid status.
              </div>
            </div>
          </div>
        </div>-->

        <!-- row -->
        <div class="form-row">
          <!-- start date -->
          <div class="col-md">
            <div class="form-group">
              <label for="purchasedOn"><i class="fal fa-calendar-alt"></i> Start Date</label>
              <input type="date" v-bind:class="{
                'form-control': true,
                'is-invalid': !validDate(warrantyEditData.start) && bluredStart
              }" v-on:blur="bluredStart = true" v-model="warrantyEditData.start" />

              <div class="invalid-feedback">
                Please enter a valid start date.
              </div>
            </div>
          </div>

          <!-- end date -->
          <div class="col-md">
            <div class="form-group">
              <label for="purchasedAt"><i class="fal fa-calendar-alt"></i> End Date</label>
              <input type="date" v-bind:class="{
                'form-control': true,
                'is-invalid': !validDate(warrantyEditData.end) && bluredEnd
              }" v-on:blur="bluredEnd = true" v-model="warrantyEditData.end" />
              <div class="invalid-feedback">
                Please enter a valid end date.
              </div>
            </div>
          </div>
        </div>

        <!-- duration -->
        <div class="form-group">
          <label for="purchasedAt"><i class="fal fa-clock"></i> Duration in years</label>
          <input type="text" disabled class="form-control" v-model="warrantyEditData.duration" />
        </div>

        <!-- alert -->
        <b-alert v-if="showInputError" show variant="danger"><i class="fad fa-exclamation-circle"></i>
          {{ validationMsg }}
        </b-alert>
      </div>

      <!-- dialog bottom -->
      <div class="jdialog-bottom with-cancel">
        <button @click="$bvModal.hide('edit-warranty-modal')" class="jbtn jbtn-sm jbtn-red">
          <i class="fa fa-times"></i> Cancel
        </button>
        <button @click="updateWarranty" class="jbtn jbtn-sm">
          <i class="fa fa-check"></i> {{ editMode ? "Update" : "Create" }}
        </button>
      </div>
    </b-modal>

    <!-- ----- add/edit delivery modal --------------------------------- -->
    <b-modal :hide-header="true" :hide-footer="true" id="add-edit-delivery-modal" ref="add-edit-delivery-modal" centered
      title="BootstrapVue">
      <div class="jdialog-top">
        <div class="close-container">
          <h3>
            <strong>{{ editDeliveryMode ? "Edit" : "Add" }} Delivery</strong>
          </h3>
          <span @click="$bvModal.hide('add-edit-delivery-modal')" class="ml-auto jclose"><i
              class="fa fa-times "></i></span>
        </div>
        <p>
          <small>Add and edit deliveries for this Watch.</small>
        </p>
      </div>
      <div class="jdialog-main">
        <!-- row -->
        <div class="form-row">
          <!-- name -->
          <div class="col-md">
            <div class="form-group">
              <label for="serial"><i class="fal fa-tag"></i> Carrier Name</label>
              <input type="text" v-bind:class="{
                'form-control': true
              }" v-model="deliveryData.delivery.carrier" />
              <small>Optional.</small>
            </div>
          </div>

          <!-- status -->
          <div class="col-md">
            <div class="form-group">
              <label for="status"><i class="fal fa-exclamation-circle"></i> Status</label>
              <b-form-select v-model="deliveryData.delivery.status"
                :options="$C.getFormattedOptionsArray($C.STATUS.WARRANTY_STRINGS)"></b-form-select>
              <div class="invalid-feedback">
                Please select a valid status.
              </div>
            </div>
          </div>
        </div>

        <!-- row -->
        <div class="form-row">
          <!-- expedition date -->
          <div class="col-md">
            <div class="form-group">
              <label for="purchasedOn"><i class="fal fa-calendar-alt"></i> Expedition Date</label>
              <input type="date" v-bind:class="{
                'form-control': true,
                'is-invalid': !validDate(deliveryData.delivery.dispatch) && bluredDeliveryDispatch
              }" v-on:blur="bluredDeliveryDispatch = true" v-model="deliveryData.delivery.dispatch" />

              <div class="invalid-feedback">
                Please enter a valid date.
              </div>
            </div>
          </div>

          <!-- delivery date -->
          <div class="col-md">
            <div class="form-group">
              <label for="purchasedAt"><i class="fal fa-calendar-alt"></i> Expected Arrival Date</label>
              <input type="date" v-bind:class="{
                'form-control': true,
                'is-invalid': !validDate(deliveryData.delivery.arrival) && bluredDeliveryArrival
              }" v-on:blur="bluredDeliveryArrival = true" v-model="deliveryData.delivery.arrival" />
              <div class="invalid-feedback">
                Please enter a valid date.
              </div>
            </div>
          </div>
        </div>

        <!-- tracking -->
        <div class="form-group">
          <label for="purchasedAt"><i class="fal fa-clock"></i> Tracking ID</label>
          <input type="text" class="form-control" v-model="deliveryData.delivery.trackingId" />
          <small>Optional.</small>
        </div>

        <!-- alert -->
        <b-alert v-if="showInputError" show variant="danger"><i class="fad fa-exclamation-circle"></i>
          {{ validationMsg }}
        </b-alert>
      </div>

      <!-- dialog bottom -->
      <div class="jdialog-bottom with-cancel">
        <button @click="$bvModal.hide('add-edit-delivery-modal')" class="jbtn jbtn-sm jbtn-red">
          <i class="fa fa-times"></i> Cancel
        </button>
        <button @click="updateDelivery" class="jbtn jbtn-sm">
          <i class="fa fa-check"></i> {{ editDeliveryMode ? "Update" : "Create" }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import C from "@/const";
import toast from "@/assets/js/toast";
import moment from "moment";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;

export default {
  name: "Watch",
  // components: {
  //   Datepicker: VueDatepickerUi,
  // },
  data() {
    return {
      watchStatusOptions: C.getFormattedOptionsArray(C.STATUS.WATCH_STRINGS),

      //page display logic
      editMode: false,
      editDeliveryMode: false,
      isLoading: false,
      isLoadingWarranty: true,
      showErrorWarranty: false,
      showWarrantySlots: false,
      showErrorDelivery: false,

      //variables
      userId: "",
      watchId: "",
      modelId: "",
      model: {
        name: ""
      },

      //form error alert
      showInputError: false,
      validationMsg: "",

      //form button
      isSaving: false,

      //watch data form
      valid: false,
      bluredModel: false,
      // bluredSerialNumber: false,
      bluredPurchasedOn: false,
      bluredPurchasedAt: false,
      //warranty data form
      bluredStart: false,
      bluredEnd: false,
      //delivery data form
      bluredDeliveryDispatch: false,
      bluredDeliveryArrival: false,

      //watch data for edit
      editData: {
        modifiedOn: new Date(),
        status: 0,
        serial: "",
        purchasedOn: "",
        purchasedAt: ""
      },
      // warranty edit data
      warrantyEditData: {
        index: 0,
        start: new Date(),
        end: new Date(),
        duration: 3,
        status: 0
      },

      // delivery edit data
      deliveryData: {
        delivery: {
          status: 0,
          carrier: "",
          dispatch: new Date(),
          arrival: new Date(),
          trackingId: ""
        }
      },

      // payment
      paymentId: "",
      initialPayment: false,
      fullPayment: false,
      paymentMethod: "",

      // delivery
      delivered: false,
      deliveredOn: ""
    };
  },
  methods: {
    //load watch data
    getWatch: function (watchId) {
      const id = watchId;
      console.log("executing allWatches with id: " + id);
      db.collection(C.COLLECTION.WATCHES)
        .doc(watchId)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            const docdata = doc.data();
            docdata.id = doc.id;
            console.log("watch found: " + docdata.id, " => ", docdata);
            this.watches.push(docdata);
          });
        })
        .catch(error => {
          console.log("Error getting watch document: ", error);
        });
    },
    // open edit watch dialog
    showEditWatchDialog() {
      this.editData.serial = this.watch.serial;
      this.editData.status = this.watch.status;
      // this.editData.purchasedOn = this.watch.purchasedOn;
      this.editData.purchasedOn = moment(C.getDateFromTimestamp(this.watch.purchasedOn)).format("YYYY-MM-DD");
      this.editData.purchasedAt = this.watch.purchasedAt;
      this.$refs["edit-watch-modal"].show();
    },
    // save watch data to db
    updateWatchInfo() {
      if (!this.validate()) return;
      //format date from string to timestamp
      this.editData.purchasedOn = new Date(this.editData.purchasedOn + "T00:00:00.000");
      //save to db
      var docRef = db.collection(C.COLLECTION.WATCHES).doc(this.watchId);
      docRef
        .set(this.editData, { merge: true })
        .then(() => {
          this.$refs["edit-watch-modal"].hide();
          toast.success("User profile updated.");
        })
        .catch(error => {
          toast.error("Couldn't update user profile. " + error.message);
        });
    },
    // OPEN new warranty dialog
    showWarrantyDialog() {
      this.$refs["edit-warranty-modal"].show();
    },
    // OPEN edit warranty dialog
    showEditWarrantyDialog(index) {
      this.editMode = true;
      this.warrantyEditData.index = index;
      const warranty = this.watch.warranties[index];

      this.warrantyEditData.start = moment(C.getDateFromTimestamp(warranty.start)).format("YYYY-MM-DD");
      this.warrantyEditData.end = moment(C.getDateFromTimestamp(warranty.end)).format("YYYY-MM-DD");
      this.warrantyEditData.duration = this.watch.warranties[index].duration;
      this.warrantyEditData.status = warranty.status;
      this.$refs["edit-warranty-modal"].show();
    },
    // UPDATE warranty in db
    updateWarranty() {
      if (!this.validateWarranty()) return;

      const newWarranties = this.watch.warranties;
      newWarranties[this.warrantyEditData.index].start = new Date(this.warrantyEditData.start + "T00:00:00.000");
      newWarranties[this.warrantyEditData.index].end = new Date(this.warrantyEditData.end + "T00:00:00.000");
      newWarranties[this.warrantyEditData.index].duration = this.warrantyEditData.duration;

      // //if its the active slot need to update current warranty info
      // if (this.warrantyData.warrantyActive == this.warrantyEditData.index) {
      //   this.warrantyData.warranty.start = new Date(this.warrantyEditData.start + "T00:00:00.000");
      //   this.warrantyData.warranty.end = new Date(this.warrantyEditData.end + "T00:00:00.000");
      //   this.warrantyData.warranty.duration = this.warrantyEditData.duration;
      // }

      //save to db
      var docRef = db.collection(C.COLLECTION.WATCHES).doc(this.watchId);
      docRef.update({
        warranties: newWarranties
      })
        .then(() => {
          //finalize
          this.$refs["edit-warranty-modal"].hide();
          toast.success("Warranty edited.");
        })
        .catch(error => {
          toast.error("Couldn't edit warranty. " + error.message);
        });
    },
    // SET active warranty slot 0-2
    setActiveWarranty(index) {
      console.log(index);
      // validate index
      if (!(index >= 0 && index <= 2)) return;
      if (this.watch.warrantyActive == index) {
        toast.error("Already active");
        return;
      }

      const newWarranties = this.watch.warranties;

      // set the right status, depending on which one we activate, the others need to be inactive or expired
      newWarranties[index].status = C.STATUS.WARRANTY.ACTIVE;
      if (index == 2) {
        newWarranties[0].status = C.STATUS.WARRANTY.EXPIRED;
        newWarranties[1].status = C.STATUS.WARRANTY.EXPIRED;
      } else if (index == 1) {
        newWarranties[0].status = C.STATUS.WARRANTY.EXPIRED;
        newWarranties[2].status = C.STATUS.WARRANTY.INACTIVE;
      } else {
        newWarranties[1].status = C.STATUS.WARRANTY.INACTIVE;
        newWarranties[2].status = C.STATUS.WARRANTY.INACTIVE;
      }

      //save to db
      db.collection(C.COLLECTION.WATCHES).doc(this.watchId).update({
        warranties: newWarranties,
        warrantyActive: index
      })
        .then(() => {
          //finalize
          this.$refs["edit-warranty-modal"].hide();
          toast.success("Active warranty updated.");
        })
        .catch(error => {
          toast.error("Couldn't set active warranty. " + error.message);
        });
    },
    // OPEN add delivery dialog
    showAddDeliveryDialog() {
      this.editDeliveryMode = false;
      this.$refs["add-edit-delivery-modal"].show();
    },
    // OPEN add delivery dialog
    showEditDeliveryDialog() {
      this.editDeliveryMode = true;
      this.$refs["add-edit-delivery-modal"].show();
    },
    // UPDATE delivery in watch doc
    updateDelivery() {
      if (!this.validateDelivery()) return;

      //format dates from string to timestamp
      this.deliveryData.delivery.dispatch = new Date(this.deliveryData.delivery.dispatch + "T00:00:00.000");
      this.deliveryData.delivery.arrival = new Date(this.deliveryData.delivery.arrival + "T00:00:00.000");

      //save to db
      var docRef = db.collection(C.COLLECTION.WATCHES).doc(this.watchId);
      docRef
        .set(this.deliveryData, { merge: true })
        .then(() => {
          //finalize
          this.$refs["add-edit-delivery-modal"].hide();
          if (this.editDeliveryMode) toast.success("Delivery edited.");
          else toast.success("Delivery added.");
        })
        .catch(error => {
          toast.error("Couldn't update delivery. " + error.message);
        });
    },
    // DELETE delivery in watch doc
    deleteDelivery() {
      if (confirm("Do you really want to delete this delivery?")) {
        var docRef = db.collection(C.COLLECTION.WATCHES).doc(this.watchId);
        docRef
          .set({ delivery: null }, { merge: true })
          .then(() => {
            this.showErrorDelivery = true;
            toast.success("Delivery successfully deleted!");
          })
          .catch(error => {
            toast.error("Error removing delivery. " + error.message);
          });
      }
    },
    // VALIDATE edit watch form
    validate() {
      // this.bluredSerialNumber = true;
      // if (!this.validSerial(this.editData.serial)) {
      //   this.valid = false;
      //   return false;
      // }
      this.bluredPurchasedOn = true;
      if (!this.validDate(this.editData.purchasedOn)) {
        this.valid = false;
        return false;
      }
      this.bluredPurchasedAt = true;
      if (!this.validInput(this.editData.purchasedAt)) {
        this.valid = false;
        return false;
      }

      this.valid = true;
      return true;
    },
    validateWarranty() {
      this.bluredStart = true;
      if (!this.validDate(this.warrantyEditData.start)) {
        this.valid = false;
        return false;
      }
      this.bluredEnd = true;
      if (!this.validDate(this.warrantyEditData.end)) {
        this.valid = false;
        return false;
      }

      this.valid = true;
      return true;
    },
    validateDelivery() {
      this.bluredDeliveryDispatch = true;
      if (!this.validDate(this.deliveryData.delivery.dispatch)) {
        this.valid = false;
        return false;
      }
      this.bluredDeliveryArrival = true;
      if (!this.validDate(this.deliveryData.delivery.arrival)) {
        this.valid = false;
        return false;
      }

      this.valid = true;
      return true;
    },
    validInput(input) {
      return input.trim().length > 0;
    },
    validSerial(input) {
      return input.trim().length > 9;
    },
    validDate(input) {
      return input != null && input != "" && input != "undefined";
    },
    //not needed anymore see $C
    accountTypeToStr(type) {
      return C.TYPE_INDEX[type];
    },
    //add in constants see $C
    hasActiveStatus(status) {
      if (status == 0 || status == 1 || status == 2 || status == 10 || status == 12) return true;
      else return false;
    },
    //calculate warranty duration for moment() input
    calculateDuration(start, end) {
      var duration = end.diff(start, "years", true);
      return duration < 0 ? 0 : duration;
    },
    getActiveWarranty(watch) {
      return watch.warranties[watch.warrantyActive];
    }
  },
  computed: {
    user: {
      get() {
        return this.$store.getters.getUser;
      }
    },
    userLabel: {
      get() {
        return this.$store.getters.getUserLabel;
      }
    },
    watch: {
      get() {
        return this.$store.getters.getWatch;
      }
    }
  },
  created() {
    //get watch id from params
    if (this.$route.params.id != null && this.$route.params.id != "") {
      this.watchId = this.$route.params.id;
    }

    //load watch
    var watchRef = db.collection(C.COLLECTION.WATCHES).doc(this.watchId);
    watchRef.onSnapshot(watchDoc => {
      console.log("WATCH BEING UPDATED...");
      this.$store.commit("setWatch", watchDoc.data());
      this.userId = watchDoc.data().userId;

      this.showErrorDelivery = watchDoc.data().delivery == null;
      console.log("------------- showErrorDelivery: " + this.showErrorDelivery);

      //load watch model, TODO: add to store
      var watchModelRef = db.collection(C.COLLECTION.WATCH_CATALOG).doc(watchDoc.data().modelId);
      watchModelRef
        .get()
        .then(watchModelDoc => {
          this.model = watchModelDoc.data();
        })
        .catch(function (error) {
          console.log("Error getting WATCH MODEL document:", error);
        });

      //load user
      var userDocRef = db.collection(C.COLLECTION.USERS).doc(this.userId);
      userDocRef
        .get()
        .then(userDoc => {
          this.$store.commit("setUser", userDoc.data());
        })
        .catch(function (error) {
          console.log("Error getting USER document:", error);
        });

    });
  }
};
</script>

<style scoped></style>
